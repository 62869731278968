import { Logo } from 'assets/images';
import { Box, Button, Container, Flex, Text } from 'theme-ui';
import { ContainerLayoutStyled } from './styled';
import { useLocation } from 'react-router-dom'
import { useTheme } from 'styled-components';

export const ContainerLayout = ({ children, ...props }) => {
    const location = useLocation();

    const theme = useTheme()
    const isPartner = ['/partner', '/partner/'].includes(location.pathname)

    return (
        <ContainerLayoutStyled {...props}>
            <Container>
                <Flex
                    py={4}
                    sx={{
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        [`@media (max-width: ${theme.breakpoints[0]})`]: {
                            flexDirection: 'column',
                            alignItems: isPartner ? 'center' : 'flex-start'
                        }
                    }}
                >
                    <Logo sx={{ height: '37px', width: 'auto' }} />
                    {isPartner && <Box sx={{
                        [`@media (max-width: ${theme.breakpoints[0]})`]: {
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                            alignItems: 'center',
                            mt: 4,
                        }
                    }}>
                        <Text
                            as="a"
                            href={`${process.env.REACT_APP_API_BASE_URL}/login`}
                            target="__blank"
                            rel="noopener noreferrer"
                            sx={{
                                textTransform: 'uppercase',
                                mr: 4,
                                color: 'white',
                                textDecoration: 'none',
                                fontWeight: 500
                            }}>Partner Login</Text>
                        <Button as="a" href="#register">Request Access</Button>
                    </Box>}
                </Flex>
                {children}
            </Container>
        </ContainerLayoutStyled>
    );
};
