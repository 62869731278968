import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { Field } from '~/Fields/FormikField';
import { Box, Button, Field as FieldUI, Flex, Heading, Label, Text } from 'theme-ui';
import { Select } from '~/Fields/Select';
import { getPartnerOptionsAsync, storePartnerRequestAsync } from 'features/appThunks';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { Graph, InsightsReport } from 'assets/images';
import { ListItemStyled, ListStyled } from '~/Common/ImageList/styled';
import { Icon } from '~/Common/Icon';
import { Markdown } from '~/Common/Markdown';
import { useTheme } from 'styled-components';
import { useQuery } from 'hooks/useQuery';

const validationSchema = Yup.object().shape({
    company_name: Yup.string().required('Company name is required'),
    first_name: Yup.string().required('First name is required'),
    last_name: Yup.string().required('Last name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    phone: Yup.string().required('Phone number is required'),
    industry: Yup.object().required('Industry is required'),
    lead_source: Yup.string(),
    terms_accepted: Yup.boolean().oneOf([true], 'You must accept the privacy policy')
});

export const PartnerUncoupled = ({
    partner,
    partnerOptions,
    getPartnerOptionsAsync,
    storePartnerRequestAsync,
}) => {
    useEffect(() => {
        if (partnerOptions?.status === 'idle') {
            getPartnerOptionsAsync();
        }
    }, [partnerOptions, getPartnerOptionsAsync]);

    const handleSubmit = async (values, { setErrors }) => {
        try {
            await storePartnerRequestAsync({
                    ...values,
                    industry_id: values?.industry?.value,
                })
                .unwrap()
                .then(data => console.log(data));
        } catch (e) {
            toast.error(e?.data?.message);

            if (e.data.errors) {
                setErrors(e.data.errors)
            }
        }
    };

    const theme = useTheme()
    let query = useQuery()

    const content = partnerOptions?.data?.content
    const headerContent = partnerOptions?.data?.headerContent

    return <>
        <Flex
            sx={{
                width: '100%',
                justifyContent: 'space-between',
                py: 6,
                alignItems: 'center',
                flexDirection: 'row',
                [`@media (max-width: ${theme.breakpoints[0]})`]: {
                    flexDirection: 'column',
                    py: 5,
                }
            }}
        >
            <Box
                sx={{
                    width: '50%',
                    [`@media (max-width: ${theme.breakpoints[0]})`]: {
                        width: '100%',
                        mb: 4,
                    }
                }}
            >
                {headerContent ? <Markdown content={headerContent} /> : <>
                    <Heading variant="subheading" sx={{ mb: 2 }}>Partner Access</Heading>
                    <Heading>The ultimate tool for business owners to understand and maximize their enterprise value</Heading>
                </>}
                <Button sx={{ mt: 5 }} as="a" variant='primary' href="#about">Find Out More</Button>
            </Box>
            <Box
                sx={{
                    width: '40%',
                    [`@media (max-width: ${theme.breakpoints[0]})`]: {
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                    }
                }}
            >
                <InsightsReport />
            </Box>
        </Flex>

        <Flex
            sx={{
                width: '100%',
                justifyContent: 'space-between',
                py: 6,
                alignItems: 'center',
                flexDirection: 'row',
                [`@media (max-width: ${theme.breakpoints[0]})`]: {
                    flexDirection: 'column',
                    py: 5,
                }
            }}
            id="about"
        >
            <Box
                sx={{
                    width: '40%',
                    [`@media (max-width: ${theme.breakpoints[0]})`]: {
                        width: '100%',
                        mb: 4,
                        display: 'flex',
                        justifyContent: 'center',
                    }
                }}
            >
                <Graph />
            </Box>
            <Box
                sx={{
                    width: '50%',
                    [`@media (max-width: ${theme.breakpoints[0]})`]: {
                        width: '100%'
                    }
                }}
            >
                {content ? <Markdown content={content} /> : <>
                    <Heading sx={{ mb: 4 }}>Increase the enterprise value for business owners in your community.</Heading>
                    <Text as='p' sx={{ mb: 4 }}>Partner with Cultivate Advisors and bring the most talked about Enterprise Value tool to your community of business owners. By providing this tool, you'll receive the following benefits:</Text>
                    <ListStyled isGrid={true} style={{ marginTop: 0, gridTemplateColumns: 'auto auto' }}>
                        {['Help businesses grow, so you grow', 'Attract and retain more business owners', 'Provide value to the businesses in your network', 'Better insight & data into the businesses you work with'].map((item, index) => (
                            <ListItemStyled key={index} style={{ fontWeight: 300, lineHeight: 1 }}>
                                <Icon icon="tick" sx={{ flexShrink: 0 }} />
                                {item}
                            </ListItemStyled>
                        ))}
                    </ListStyled>
                </>}
            </Box>
        </Flex>

        <Flex
            sx={{
                width: '100%',
                justifyContent: 'space-between',
                py: 6,
                flexDirection: 'row',
                [`@media (max-width: ${theme.breakpoints[0]})`]: {
                    flexDirection: 'column',
                    py: 5,
                }
            }}
            id="register"
        >
            <Box
                sx={{
                    width: '30%',
                    [`@media (max-width: ${theme.breakpoints[0]})`]: {
                        width: '100%',
                        mb: 4,
                    }
                }}
            >
                <Heading mb={4}>Request access to start using this tool for free today!</Heading>
                <Text as='p'>Apply by filling out the information below and we will create your account shortly.</Text>
            </Box>
            <Box
                sx={{
                    width: '50%',
                    [`@media (max-width: ${theme.breakpoints[0]})`]: {
                        width: '100%'
                    }
                }}
            >
                {partner.status === 'fulfilled' ?
                    <Heading>{t('pages.partner.success')}</Heading> :
                    <Formik
                        initialValues={{
                            company_name: '',
                            first_name: '',
                            last_name: '',
                            email: '',
                            phone: '',
                            industry: '',
                            lead_source: '',
                            terms_accepted: false,
                            utm_source: query.get('utm_source'),
                            utm_campaign: query.get('utm_campaign')
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                    <Form>
                        <Field
                            label={t('pages.partner.companyName.label')+'*'}
                            placeholder={t(
                                'pages.partner.companyName.placeholder'
                            )}
                            variant="contactInput"
                            name="company_name"
                            as={FieldUI}
                        />
                        <Field
                            label={t('pages.partner.firstName.label')+'*'}
                            placeholder={t(
                                'pages.partner.firstName.placeholder'
                            )}
                            variant="contactInput"
                            name="first_name"
                            as={FieldUI}
                        />
                        <Field
                            label={t('pages.partner.lastName.label')+'*'}
                            placeholder={t(
                                'pages.partner.lastName.placeholder'
                            )}
                            variant="contactInput"
                            name="last_name"
                            as={FieldUI}
                        />
                        <Field
                            label={t('pages.partner.email.label')+'*'}
                            placeholder={t(
                                'pages.partner.email.placeholder'
                            )}
                            variant="contactInput"
                            name="email"
                            as={FieldUI}
                        />
                        <Field
                            label={t('pages.partner.phone.label')+'*'}
                            placeholder={t(
                                'pages.partner.phone.placeholder'
                            )}
                            variant="contactInput"
                            name="phone"
                            as={FieldUI}
                        />
                        <Label sx={{ display: 'flex', flexDirection: 'column', marginBottom: '24px' }}>
                            <Text sx={{ mb: 2 }}>{t('pages.partner.industry.label')+'*'}</Text>
                            <Field
                                placeholder={t(
                                    'pages.partner.industry.placeholder'
                                )}
                                name="industry"
                                component={Select}
                                options={partnerOptions?.data?.industries ?? []}
                                style={{ marginBottom: '24px' }}
                                errorSx={{
                                    top: '-28px'
                                }}
                            />
                        </Label>
                        <Field
                            label={t('pages.partner.source.label')}
                            variant="contactInput"
                            name="lead_source"
                            as={FieldUI}
                        />
                        <Label sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginBottom: '24px',
                            position: 'relative',
                            paddingTop: '28px',
                        }}>
                            <Field
                                name="terms_accepted"
                                type="checkbox"
                                separateError={true}
                            />
                            <Text as="p" sx={{ ml: 2 }}>
                                I agree to Cultivate Advisors <a href="https://cultivateadvisors.com/privacy-policy/" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>privacy policy</a>.
                            </Text>
                        </Label>
                        <Button type="submit">
                            {t('pages.partner.submit')}
                        </Button>
                    </Form>
                </Formik>}
            </Box>
        </Flex>
    </>
};

export const Partner = connect(
    ({ app }) => ({
        partner: app.partner,
        partnerOptions: app.partnerOptions,
    }),
    {
        getPartnerOptionsAsync,
        storePartnerRequestAsync,
    }
)(PartnerUncoupled);
